import React, { useState, useEffect, useRef } from 'react';
import './Terminal.css';
import { logEvent } from "firebase/analytics";

const Terminal = ({ analytics }) => {

  const [input, setInput] = useState('');
  const [output, setOutput] = useState(["Welcome to my website! Enter 'help' to get started"]);
  const inputRef = useRef(null)

  const promptMessage = 'guest@aidanmeharg $ '

  const supportedCommands = {
    "help": `Usage: help [command]`,
    "about": `A quick summary of myself`,
    "projects": `Brief overview of some things I've worked on`,
    "ls": `List files in the current directory\nUsage: ls`,
    "cat": `Read a file sequentially and print to standard out\nUsage: cat [filename]`,
    "dog": `Man's best friend.`,
    "echo": `Can be heard but not seen and only speaks when spoken to.\nUsage: echo [...args]`,
    "contact": `Some ways to reach me.`,
    "repo": `Opens a new tab to showcase an underwhelming github profile`,
    "resume": `Opens a new tab with a pdf of my resume`,
    "clear": `Clear terminal output`
  }

  const files = {
    "about_me.txt": `
                    Hi I'm Aidan! I'm a fourth year student at the University of British Columbia studying
                    computer science + statistics. Thank you for checking out my website :)

                    I am super interested in the tech field, and always enjoy learning new things. In particular,
                    software development and machine learning (cliché I know...) really spark my curiosity. I'm
                    currently interning at Rivian automotive working as a software integration engineer.
                    
                    When I'm not busy with work / school, I like to:
                        - cook + eat delicious food
                        - play + listen to music (I play piano + saxophone)
                        - spend time outside in beautiful British Columbia


                    Enter 'projects' to get a quick overview of some of the things I've worked on
                    `,
    "projects_overview.txt": `
                    * This website *
                    > built from scratch with HTML + CSS
                    > designed to give the look and feel of a classic bash terminal
                    > it's got tab completion!!!
                    > sorry if this is bad UX @hiring-managers - all the info you really need is on my linkedin + resume
                    
                    * JaffHour - Mobile App *
                    > time and expense tracking app for iOS
                    > developed with swift + swiftUI
                    > created custom calendar component
                    > file exporting capabilities
                    https://github.com/aidanmeharg/jaffhour

                    * Song Genre Prediction *
                    > worked in a team of four to predict song genres from audio features
                    > data analysis using R
                    > model tuning + cross validation
                    > data visualization
                    https://github.com/aidanmeharg/dsci-100-2022W2-group-5

                    * Tank Battles *
                    > 2 player arcade style game written in Java
                    > invaluable experience with object oriented design
                    > first time coding -> had so much fun I switched my undergraduate major to computer science
                    https://github.com/aidanmeharg/tankbattles
                `
  }

  const processCommand = (command, args) => {
        if (command === '') {
            return ''
        }
        switch (command) {
            case "help":
                if (args && args.length === 1) {
                    if (args[0] in supportedCommands) {
                        return supportedCommands[args[0]]
                    } else {
                        return `No such command: ${args[0]}`
                    }
                }
                return `
                        Hey! Thanks for checking out my website. If you're in a rush you can enter 'about' to get
                        a quick overview of me.

                        Frequently used commands: ${Object.keys(supportedCommands).join(', ')}
                        Usage: help [command]
                        
                        `
            case "about":
                return files["about_me.txt"]
            case "projects":
                return files["projects_overview.txt"]
            case "ls":
                return Object.keys(files).join("\n")
            case "cat":
                if (args.length !== 1) {
                    return "USAGE: cat [filename]"
                } else if (args[0] in files) {
                    return files[args[0]]
                } else {
                    return `File not found: ${args[0]}`
                }
            case "dog":
                return "WOOF!"
            case "echo":
                return args.join(' ')
            case "contact":
                return " \nemail: aidanmeharg@gmail.com\nlinkedin: linkedin.com/in/aidanmeharg\ninstagram: instagram.com/aidanmeharg\n "
            case "repo":
                setTimeout(() => {window.open("https://github.com/aidanmeharg", "_blank")}, 1500)
                return "Opening github.com/aidanmeharg ..."
            case "resume":
                setTimeout(() => {window.open("./resume_24.pdf", "_blank")}, 1500)
                return "Opening resume in a new tab ..."
            // Goofinstuf
            case "mkdir":
                return "coming soon ..."
            case "touch":
                return "that'd be cool - what would you use it for?"
            case "python":
                return "ssssssssss ..."
            case "cd":
                return "Sorry, you're stuck here ..."
            case "pwd":
                return "/best/portfolio/ever"
            case "sudo":
                return "Nice try ..."
            case "vim":
                return "Nope, try code"
            case "code":
                return "Nope, try vim"
            default:
                return `Invalid command: ${command}\nTry: 'help'`
        }
    }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      
      logEvent(analytics, `user_input_${input.split(' ').join('_')}`)
      const [command, ...args] = input.split(' ')
      
      // This should be in processCommand but too lazy
      if (command === "clear") {
        setOutput([])
        setInput('')
        return
      }
      const result = processCommand(command, args)
      setOutput([...output, `${promptMessage} ${input}`, ...result.split('\n')]);
      setInput('');

    } else if (e.key === `Tab`) {

        e.preventDefault()

        const [command, ...args] = input.split(' ')

        if (args && args.length === 1) {
            // User should be typing in filename
            const autoCompleteFilename = Object.keys(files).find((fileName) => {
                return fileName.startsWith(args[0])
            })
            if (autoCompleteFilename) {
                setInput(`${command} ${autoCompleteFilename}`)
            }
        }
        if (input.split(' ').length === 1) {
            // User should be typing command name
            const autoCompleteCommand = Object.keys(supportedCommands).find((commandName) => {
                return commandName.startsWith(command)
            })
            if (autoCompleteCommand) {
                setInput(autoCompleteCommand)
            }
        }
    }
  };

  const scrollToBottom = () => {
    inputRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [output]);

  return (
    <div className="terminal" onClick={() => inputRef.current.focus()}>
      {output.map((line, index) => {
        const trimmedLine = line.trim();
        const containsLink = trimmedLine.includes('https://');

        return (
            <div key={index} className='output-line'>
                {containsLink ? (
                    <a href={trimmedLine} target="_blank" rel="noopener noreferrer">
                        {trimmedLine}
                    </a>
                ) : (
                    trimmedLine === '' ? '\u00A0' : trimmedLine
                )}
            </div>
        );
        })}
      <div className="input-line">
        <span className="prompt">{promptMessage}</span>
        <span style={{visibility: "hidden"}}>{">"}</span>
        <input
          type="text"
          spellCheck="false"
          autoCapitalize="none"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          autoFocus
        />
      </div>
    </div>
  );
};

export default Terminal;