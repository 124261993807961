import './App.css';
import Terminal from './Terminal';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDTx8z7zULMiaaAuc3B6iZibwmjL37nQBk",
  authDomain: "personal-site-862cd.firebaseapp.com",
  projectId: "personal-site-862cd",
  storageBucket: "personal-site-862cd.appspot.com",
  messagingSenderId: "1028946336552",
  appId: "1:1028946336552:web:2f76e8f8b4c983ccb64e1d",
  measurementId: "G-1ZG9BXD74Q"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="Aidan Meharg" style={{backgroundColor: "#1B1919", height: "100vh"}}>

      <div className="titleBar" style={{display: "flex", justifyContent: "flex-start", alignItems: "center", alignContent: "center", paddingLeft:"2%"}}>
        <img src="./aimLogoNav.png" alt="Logo" className="logo" />
        <div className="links">
          <a href="https://www.linkedin.com/in/aidanmeharg" target="_blank" rel="noreferrer" onClick={() => logEvent(analytics, "click_linkedin")}>linkedin</a>
          <a href="./resume_24.pdf" target="_blank" rel="noreferrer" onClick={() => logEvent(analytics, "click_resume")}>resume</a>
          <a href="mailto:aidanmeharg@gmail.com" onClick={() => logEvent(analytics, "click_email")}>email</a>
          <a href="https://github.com/aidanmeharg" target="_blank" rel="noreferrer" onClick={() => logEvent(analytics, "click_github")}>github</a>
        </div>
      </div>
      <div style={{padding: "2%", paddingTop: 0}}>
        <Terminal analytics={analytics}/>
      </div>
    </div>
  );
}

export default App;
